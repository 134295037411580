body {
  background-color: #202a33;
  margin: 0;
  padding: 0;
  color: #cdcdcd;
  text-align: center;
  font-family: 'Gotu', 'Crimson Text', sans-serif;
}

a {
  color: #fff;
}

h1 {
  text-transform: uppercase;
  color: #fff;
}

h1, h2 {
  font-family: 'Milonga', cursive;
}

h2 {
  margin-top: 0;
}

p {
  line-height: 1.2;
  margin-bottom: 0;
}

small {
  font-size: 0.8;
}

.tandaPlayer {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 0 1em;
  margin: 0 auto;
  max-width: 1200px;
}

.tandaBoxesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tandaBox {
  margin: 1vw;
}
